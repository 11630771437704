* {
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E9EDF2;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTimelineItem-root:before {
  display: none;
}

/* Custom scrollbar styling */
*::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f0f5fa; /* Light background for the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

*::-webkit-scrollbar-thumb {
  background-color: #BFCAD9; /* Grayish-blue color for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f0f5fa; /* Adds padding between the thumb and the track */
}

*::-webkit-scrollbar-thumb:hover {
  background-color:  #8692A3; /* Darker shade for the thumb on hover */
  cursor: pointer; /* Pointer cursor on hover */
}
*::-webkit-scrollbar-thumb:active {
  background-color:  #636F7F; /* Darker shade for the thumb on hover */
  cursor: pointer; /* Pointer cursor on hover */
}

@keyframes wave {

  0%,
  50% {
    transform: scaleY(1);
  }

  25% {
    transform: scaleY(15);
    background-color: lightblue;
  }
}